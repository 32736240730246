
.tech-icons {
    position: relative;
    font-size: 4.5em !important;
    margin: 15px !important;
    padding: 0px 0px 15px 0px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(24, 115, 234, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(6, 35, 120, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.5s ease 0s !important;
  }
  
  @media (max-width: 767px) {
    .tech-icons {
      margin: 10px !important;
    }

    .tech-icons:hover > .tool-card {
      max-height: 70px !important;
      opacity: 1;
    }

    .tech-info {
      font-size: x-small !important;
      margin: 0 0px -20px 0px !important;
    }

    .progress-bar {
      height: 10px !important;
    }
  
  }
  
  .tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: visible !important; /* Change overflow to visible */
    border: 2.2px solid rgba(21, 125, 151, 0.883) !important;
  }
  
  .tech-icons:hover {
    /* max-height: 400px; */
  }

  .tech-icons:hover > .tool-card {
    max-height: 50px;
    opacity: 1;
  }

    .tech-icons {
        max-height: 0px;
    }

  /* .tech-icons:hover ~ .tech-icons {
    max-height: 0px;
  } */

  /* .tech-icons:hover ~ .tech-icons {
    max-height: 0px;
  } */



  /* .tool-card-wrapper:hover > .tool-card {
    max-height: 200px;
    opacity: 1;
  }
   */
  
  .tool-card {
    opacity: 1;
    max-height: 0;
    overflow: hidden;
    transition: all 0.7s ease-out; /* Optional: smooth transition */
  }
  
  .tool-card-wrapper {
    position: relative; /* Ensure relative positioning for absolute elements inside */
  }
  
  .tool-dropdown {
    /* position: absolute; */
    top: 100%; /* Adjust as needed */
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0); /* Adjust as needed */
    /* border: 1px solid rgba(21, 125, 151, 0.883); Adjust as needed */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adjust as needed */
  }
  
  .tech-info {
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    font-size: small;
    margin: 0 5px -20px 5px;
  }

  .progress-bar-container {
    width: 100%;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .progress-bar {
    height: 20px;
    border-radius: 5px;
    text-align: center;
    line-height: 20px;
    color: white;
  }
  
  